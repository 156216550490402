.main {
  max-width: 10rem;
  margin: auto;
}
.bg {
  position: relative;
  width: 100%;
  height: 4.1875rem;
  background: url("./images/bg2.png") no-repeat center/100%;
  background-color: #fff;
  /* filter: brightness(70%); */
}

.content {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0.588542rem;
  z-index: 999;
  width: 4.6875rem;
  height: 3.125rem;
}

.icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 0.838542rem;
  width: 0.838542rem;
}

.title-h5 {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 0.208333rem;
  font-weight: 400;
}

.title-h2 {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 32%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 0.411458rem;
}

.appleTarget {
  position: absolute;
  width: 2.125rem;
  height: 0.614583rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.104167rem;
}

.google {
  position: absolute;
  width: 2.125rem;
  height: 0.614583rem;
  right: 0;
  bottom: 0.104167rem;
}

.appleImg,
.googleImg {
  width: 100%;
}

.imgs img {
  width: 100%;
  vertical-align: middle;
}

.footer {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 1.197917rem;
  font-size: 0.145833rem;
  color: #ffffff;
  background-color: #171515;
}

.footer a {
  text-decoration: none;
  color: #ffffff;
}

.contact {
  position: relative;
  display: block;
  padding-top: 0.15625rem;
  padding-bottom: 0.15625rem;
}

.contact:hover .pop {
  display: block;
}

.contact:hover {
  cursor: pointer;
}

.pop:hover {
  display: block;
}
.pop::after {
  content: "";
  position: absolute;
  bottom: -0.052083rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 0.052083rem solid transparent;
  border-right: 0.052083rem solid transparent;
  border-top: 0.052083rem solid #fff;
}

.pop {
  display: none;
  position: absolute;
  left: -0.8rem;
  top: -1rem;
  width: 1.854167rem;
  color: #000;
  font-size: 0.135417rem;
  padding: 0.151042rem 0.260417rem;
  background-color: #fff;
  border-radius: 0.052083rem;
  z-index: 999;
}

.pop span {
  color: rgb(63, 187, 236);
}

.icp {
  display: block;
  padding: 30px 0;
  font-size: 13px;
  color: #808080;
  text-align: center;
}
.icp a {
  color: #808080;
  text-decoration: none;
}

.icp a:hover {
  color: #fff;
}
